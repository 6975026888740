import React from "react"
import {Parallax} from "react-spring/renderprops-addons.cjs"
import Hero from "@lekoarts/gatsby-theme-cara/src/components/hero";
import Projects from "@lekoarts/gatsby-theme-cara/src/components/projects";
// import Projects from "../../../components/projects_"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Contact from "@lekoarts/gatsby-theme-cara/src/components/contact";
import MyCookies from "../../../components/cookieCard"


const Cara = () => (
    <Layout>
        <MyCookies/>
        <Parallax pages={4}>
            <Hero offset={0}/>
            <Projects offset={1}/>
            <Contact offset={3}/>
        </Parallax>
    </Layout>
);

export default Cara
