/** @jsx jsx */
// import React from "react";
import {jsx, Flex} from "theme-ui"
import CookieConsent from "react-cookie-consent";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = {
  root: {
    background: "icon_brightest",
    display: 'flex',
    flexWrap: 'wrap',
    zIndex: 999,
    position: 'fixed',
    right: 10,
    padding: '10px 14px',
    justifyContent: 'space-between',
    backgroundColor: 'primary',
    borderRadius: 5,
    boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.2)',
    margin: '30px 30px 10px 30px',
    alignItems: `center`,

  },
  buttonAccept: {
    background: 'linear-gradient(45deg, #ffa751 5%, #86fde8 65%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(165, 204, 130, .3)',
    // color: 'white',
    float: 'right',
    height: 38,
    padding: '0 30px',
    align: 'right',
    margin: '0 0 0 5px',
    color: 'textMuted'

  },
  buttonDecline: {
    background: 'linear-gradient(45deg, #FE6B8B 10%, #FF8E53 25%, #ffa751 75%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'white',
    float: 'right',
    height: 38,
    padding: '0 30px',
    align: 'right',
    margin: '0 5px 0 0',
    color: 'textMuted'

  },
  content: {
    padding: '15px 15px 15px 15px',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 3,
    color: 'text',
    fontWeight: 'semibold',
    a: {color: 'text'},
  }
};


function MyCookies(props) {
  const {classes} = props;

  return <Flex
    sx={{
      justifyContent: `center`,
      alignItems: `center`,
      mt: 3,
      color: `text`,
      fontWeight: `semibold`,
      a: {color: `text`},
    }}
  >
    <CookieConsent
      disableStyles={true}
      location="top"
      // buttonText="Sure man!!"
      cookieName="gatsby-gdpr-google-analytics"
      expires={1}
      acceptOnScroll={true}
      enableDeclineButton
      containerClasses={classes.root}
      buttonClasses={classes.buttonAccept}
      declineButtonClasses={classes.buttonDecline}
      contentClasses={classes.content}>
      This website uses cookies to enhance the user experience.{" "}
    </CookieConsent>
  </Flex>;

}

MyCookies.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyCookies);

